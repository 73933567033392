import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/index.js'
import '../src/css/main.css';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUtensils, faHome, faUser, faBuilding, faPhone, faSignInAlt, faQuestion, faRobot, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


axios.defaults.baseURL = 'https://web-production-8fe56.up.railway.app';
axios.interceptors.request.use(
    config => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            config.headers.Authorization = 'Bearer ' + user.token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
library.add(faUtensils, faHome, faUser, faBuilding, faPhone, faSignInAlt, faQuestion, faRobot, faQuestionCircle);

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.mount('#app');