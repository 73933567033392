import axios from 'axios';

const API_URL = '/auth/';

class AuthService {
    constructor() {
        this.init();
    }

    login(user) {
        return axios
            .post(API_URL + 'login', {
                username: user.username,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken) {
                    const now = new Date();
                    const expiryDate = now.getTime() + 3600 * 1000; 
                    localStorage.setItem('accessToken', response.data.accessToken);
                    localStorage.setItem('expiryDate', expiryDate);
                    localStorage.setItem('user', JSON.stringify(response.data));
                    this.setAutoLogout(3600 * 1000); 
                }
                return response.data;
            });
    }

    logout() {
        localStorage.clear();
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
        }
    }

    getCurrentUser() {
        const expiryDate = localStorage.getItem('expiryDate');
        if (new Date().getTime() > expiryDate) {
            this.logout();
            return null;
        }
        return JSON.parse(localStorage.getItem('user'));
    }

    setAutoLogout(expiryDuration) {
        if (this.logoutTimer) {
            clearTimeout(this.logoutTimer);
        }
        this.logoutTimer = setTimeout(() => {
            this.logout();
        }, expiryDuration);
    }

    init() {
        const expiryDate = parseInt(localStorage.getItem('expiryDate'), 10);
        const now = new Date().getTime();

        if (expiryDate && now < expiryDate) {
            this.setAutoLogout(expiryDate - now);
        } else if (expiryDate) {
            this.logout();
        }
    }
}

export default new AuthService();

