<template>
    <div class="flex flex-col min-h-screen">
        <header class="bg-gray-800 text-gray-100 p-4 shadow-md flex justify-between items-center">
            <h1 class="text-2xl font-semibold flex items-center">
                <font-awesome-icon icon="robot" class="text-green-500 mr-3" />
                ChatEase
            </h1>
            <nav class="space-x-2">
                <router-link to="/">
                    <button @click="logout"
                        class="px-4 py-2 text-sm font-medium bg-red-600 rounded-lg shadow hover:bg-red-700 transition-all">
                        <font-awesome-icon icon="sign-out-alt" class="mr-2" /> Sair
                    </button>
                </router-link>
            </nav>
        </header>
        <div class="flex flex-1">
            <aside class="w-64 bg-gray-800 text-gray-200">
                <div class="flex-grow p-4 space-y-2">
                    <router-link to="/user/dashboard" class="block px-4 py-2 rounded hover:bg-gray-700">
                        <font-awesome-icon icon="home" class="mr-2" /> Inicio
                    </router-link>
                    <router-link to="/user/session" class="block px-4 py-2 rounded hover:bg-gray-700">
                        <font-awesome-icon icon="sign-in-alt" class="mr-2" /> Iniciar Sessão
                    </router-link>
                    <router-link to="/user/perguntas" class="block px-4 py-2 rounded hover:bg-gray-700">
                        <font-awesome-icon icon="question" class="mr-2" /> Criar Perguntas
                    </router-link>
                </div>
            </aside>

            <div class="flex-grow p-6 bg-gray-100">
                <router-view />
            </div>
        </div>
        <footer class="bg-gray-800 text-gray-400 text-center p-4 text-sm">
            © 2024 ChatEase. Todos os direitos reservados.
        </footer>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const logout = () => {
    localStorage.removeItem('user');
    router.push('/');
};
</script>

