<template>
    <div class="flex flex-col min-h-screen">
        <header class="bg-gray-800 text-gray-100 p-4 shadow-md flex justify-between items-center">
            <h1 class="text-2xl font-semibold flex items-center">
                <font-awesome-icon icon="robot" class="text-green-500 mr-3" />
                ChatEase
            </h1>
            <nav class="space-x-2">
                <router-link to="/">
                    <button @click="logout"
                        class="px-4 py-2 text-sm font-medium bg-red-600 rounded-lg shadow hover:bg-red-700 transition-all">
                        <font-awesome-icon icon="sign-out-alt" class="mr-2" /> Sair
                    </button>
                </router-link>
            </nav>
        </header>

        <div class="flex flex-1">
            <aside class="w-64 bg-gray-800 text-gray-200">
                <div class="flex flex-col h-full p-4">
                    <div class="flex flex-col space-y-3">
                        <router-link to="/admin/dashboard" class="menu-item">
                            <font-awesome-icon icon="home" class="mr-2" />Início
                        </router-link>
                        <router-link to="/admin/usuarios" class="menu-item">
                            <font-awesome-icon icon="user" class="mr-2" />Usuários
                        </router-link>
                        <router-link to="/admin/empresas" class="menu-item">
                            <font-awesome-icon icon="building" class="mr-2" />Empresas
                        </router-link>
                        <router-link to="/admin/numeros" class="menu-item">
                            <font-awesome-icon icon="phone" class="mr-2" />Vincular Números
                        </router-link>
                        <router-link to="/admin/session" class="menu-item">
                            <font-awesome-icon icon="sign-in-alt" class="mr-2" />Iniciar Sessão
                        </router-link>
                        <router-link to="/admin/perguntas" class="menu-item">
                            <font-awesome-icon icon="question-circle" class="mr-2" />Gerenciar Perguntas
                        </router-link>
                        <router-link to="/admin/perguntas-fixas" class="menu-item">
                            <font-awesome-icon icon="question-circle" class="mr-2" />Perguntas Fixas
                        </router-link>
                    </div>
                </div>
            </aside>

            <main class="flex-grow p-8 bg-gray-50">
                <router-view />
            </main>
        </div>

        <footer class="bg-gray-800 text-gray-400 text-center p-4 text-sm">
            © 2024 ChatEase. Todos os direitos reservados.
        </footer>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const logout = () => {
    localStorage.removeItem('user');
    router.push('/');
};
</script>

<style scoped>
.menu-item {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease;
    font-weight: 500;
    color: #e2e8f0;
    background-color: transparent;
}

.menu-item:hover {
    background-color: #4a5568;
}
</style>

