<template>
    <div>
        <h2 class="text-xl font-semibold text-gray-800 mb-2">Crescimento das Empresas</h2>
        <line-chart v-if="chartData" :data="chartData" />
    </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Decimation,
    Filler,
    SubTitle
} from 'chart.js';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Decimation,
    Filler,
    SubTitle
);

export default {
    components: {
        LineChart: Line
    },
    props: {
        empresasData: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            chartData: null
        };
    },
    watch: {
        empresasData: {
            handler(newValue) {
                if (newValue && newValue.length > 0) {
                    this.prepareChartData();
                }
            },
            immediate: true
        }
    },
    methods: {
        prepareChartData() {
            const labels = this.empresasData.map(item => item.date);
            const data = this.empresasData.map(item => item.count);
            this.chartData = {
                labels,
                datasets: [
                    {
                        label: 'Número de Empresas',
                        backgroundColor: '#42A5F5',
                        borderColor: '#42A5F5',
                        data,
                        fill: false,
                        tension: 0.1
                    }
                ]
            };
        }
    }
};
</script>