<template>
    <div class="max-w-4xl mx-auto px-4 py-8 bg-white shadow-md rounded-lg">
        <h1
            class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-gray-500 to-blue-500 text-transparent bg-clip-text">
            Sessão de acesso ao WhatsApp
        </h1>
        <br />
        <form @submit.prevent="startSession" class="mb-6">
            <div class="mb-5" v-if="isAdmin">
                <label for="empresaId" class="block text-sm font-medium text-gray-600">Empresa</label>
                <select id="empresaId" v-model="selectedEmpresaId" @change="updateNumeros"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value="" disabled>Selecione uma empresa</option>
                    <option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">{{ empresa.nome }}
                    </option>
                </select>
            </div>
            <div class="mb-5">
                <label for="numero" class="block text-sm font-medium text-gray-600">Número</label>
                <select id="numero" v-model="numeroId"
                    class="mt-2 p-3 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                    <option value="" disabled>Selecione um número</option>
                    <option v-for="numero in filteredNumeros" :key="numero.id" :value="numero.id">{{ numero.numero }}
                    </option>
                </select>
            </div>
            <button type="submit"
                class="bg-gray-600 text-white px-4 py-2 rounded-md w-full font-semibold hover:bg-gray-700 transition duration-300"
                :class="{ 'bg-gray-400 cursor-not-allowed': sessionStatus === 'connected' || loading }"
                :disabled="sessionStatus === 'connected' || loading">
                Iniciar Sessão
            </button>
        </form>
        <div v-if="loading" class="flex flex-col items-center">
            <button class="text-center text-white bg-gray-600 p-2 rounded-md">Iniciando sessão, aguarde...</button>
        </div>
        <div v-if="qrCode" class="flex flex-col items-center">
            <img :src="qrCode" alt="WhatsApp QR Code" class="w-64 h-64">
            <p>Escaneie o QR Code com o WhatsApp para conectar.</p>
            <p v-if="countdown > 0" class="text-red-500 font-semibold">Tempo restante: {{ countdown }} segundos</p>
        </div>
        <div v-if="numeroId && !loading && sessionStatus !== 'initiating' && !qrCode"
            class="flex flex-col items-center">
            <button v-if="sessionStatus === 'connected'"
                class="text-center text-white bg-green-600 p-2 rounded-md">Sessão conectada</button>
            <button v-else-if="sessionStatus === 'disconnected'"
                class="text-center text-white bg-red-600 p-2 rounded-md">Sessão desconectada</button>
        </div>
    </div>
</template>
<script setup>
import { ref, watchEffect, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import { io } from 'socket.io-client';

const selectedEmpresaId = ref('');
const numeroId = ref('');
const qrCode = ref(null);
const sessionStatus = ref('');
const loading = ref(false);
const isAdmin = ref(false);
const empresas = ref([]);
const numeros = ref([]);
const filteredNumeros = ref([]);
const qrCodeTimeout = ref(null);
const countdown = ref(0);

const socket = io('https://web-production-8fe56.up.railway.app', {
    withCredentials: true,
    transports: ['websocket', 'polling'],
    extraHeaders: {
        "My-Custom-Header": "abcd"
    }
});

const clearQrCodeTimeout = () => {
    if (qrCodeTimeout.value) {
        clearTimeout(qrCodeTimeout.value);
        qrCodeTimeout.value = null;
    }
};

const startCountdown = () => {
    countdown.value = 30;
    const interval = setInterval(() => {
        if (countdown.value > 0) {
            countdown.value--;
        } else {
            clearInterval(interval);
        }
    }, 1000);
};

watchEffect(() => {
    if (numeroId.value) {
        checkSessionStatus();
        const eventName = `qrCode-${numeroId.value}`;
        socket.on(eventName, (newQRCode) => {
            console.log(`Received QR Code for ${eventName}`);
            qrCode.value = newQRCode;
            sessionStatus.value = 'Por favor, escaneie o QR code.';
            loading.value = false;
            startCountdown();
            startQrCodeTimeout();
        });

        return () => {
            socket.off(eventName);
            console.log(`Removed listener for ${eventName}`);
        };
    }
});

onMounted(async () => {
    await checkUserRole();
    isAdmin.value ? await fetchEmpresasENumeros() : await fetchUserNumeros();
    setInterval(() => {
        if (numeroId.value) {
            checkSessionStatus();
        }
    }, 5000);
});

onUnmounted(() => {
    clearQrCodeTimeout();
    socket.off();
});

const checkUserRole = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.token) {
            const response = await axios.get('https://web-production-8fe56.up.railway.app/auth/me', {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            isAdmin.value = response.data.role === 'ADMIN';
            selectedEmpresaId.value = response.data.empresaId;
        } else {
            sessionStatus.value = 'Você precisa estar logado.';
        }
    } catch (error) {
        console.error('Erro ao verificar papel do usuário:', error);
        sessionStatus.value = 'Erro ao verificar papel do usuário.';
    }
};

const fetchUserNumeros = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const response = await axios.get(`https://web-production-8fe56.up.railway.app/numeros`, {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        const userNumeros = response.data.filter(numero => numero.empresaId === selectedEmpresaId.value);
        if (userNumeros.length > 0) {
            numeros.value = userNumeros;
            updateNumeros();
        } else {
            sessionStatus.value = 'Você não possui números associados à sua empresa.';
        }
    } catch (error) {
        console.error('Erro ao buscar números do usuário:', error);
        sessionStatus.value = 'Erro ao buscar números do usuário.';
    }
};

const fetchEmpresasENumeros = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        const responseEmpresas = await axios.get('https://web-production-8fe56.up.railway.app/empresas', {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        empresas.value = responseEmpresas.data;

        const responseNumeros = await axios.get('https://web-production-8fe56.up.railway.app/numeros', {
            headers: { Authorization: `Bearer ${user.token}` },
        });
        numeros.value = responseNumeros.data;
    } catch (error) {
        console.error('Erro ao buscar empresas e números:', error);
        sessionStatus.value = 'Erro ao buscar empresas e números.';
    }
};

const updateNumeros = () => {
    filteredNumeros.value = numeros.value.filter(numero => numero.empresaId === selectedEmpresaId.value);
};

const startSession = async () => {
    sessionStatus.value = 'initiating';
    loading.value = true;
    qrCode.value = null;
    const selectedNumero = numeros.value.find(num => num.id === numeroId.value);
    await axios.post('https://web-production-8fe56.up.railway.app/whatsapp/start-session', {
        numeroId: parseInt(numeroId.value, 10),
        numero: selectedNumero.numero
    }).then(() => {
        sessionStatus.value = 'Aguardando QR code...';
    }).catch((error) => {
        console.error('Falha ao iniciar a sessão no WhatsApp:', error);
        sessionStatus.value = 'disconnected';
        loading.value = false;
    });
};

const checkSessionStatus = async () => {
    try {
        const response = await axios.get(`https://web-production-8fe56.up.railway.app/whatsapp/session-status/${numeroId.value}`);
        sessionStatus.value = response.data.status;
        if (sessionStatus.value === 'connected') {
            setTimeout(() => {
                window.location.reload();
            }, 4000);
        }
    } catch (error) {
        console.error('Erro ao verificar o status da sessão:', error);
        sessionStatus.value = 'disconnected';
    }
};

const startQrCodeTimeout = () => {
    clearQrCodeTimeout();
    qrCodeTimeout.value = setTimeout(() => {
        qrCode.value = null;
        sessionStatus.value = 'disconnected';
        window.location.reload();
    }, 30000);
};
</script>
<style scoped>
.whatsapp-session img {
    width: 300px;
    height: 300px;
}

.bg-gray-400 {
    background-color: #cbd5e0;
}

.cursor-not-allowed {
    cursor: not-allowed;
}
</style>

