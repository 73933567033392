import { createRouter, createWebHistory } from 'vue-router';
import SessionPage from '../views/SessionPage.vue';
import NumeroForm from '../components/numeros/NumeroForm.vue';
import EmpresaForm from '../components/empresas/EmpresaForm.vue';
import PerguntaForm from '../components/perguntas/PerguntaForm.vue';
import AdminDashboard from '../views/AdminDashboard.vue';
import UserDashboard from '../views/UserDashboard.vue';
import Login from '../components/auth/LoginPage.vue';
import DashboardPage from '../components/DashboardPage.vue';
import DashboardUser from '../components/DashboardUser.vue';
import UserForm from '../components/users/UserForm.vue';
import PerguntasFixas from '../components/perguntas/PerguntasFixas.vue';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
    },
    {
        path: '/admin',
        component: AdminDashboard,
        meta: { requiresAuth: true, roles: ['ADMIN'] },
        children: [
            {
                path: 'dashboard',
                name: 'AdminHome',
                component: DashboardPage,
            },
            {
                path: 'usuarios',
                name: 'AdminUserForm',
                component: UserForm,
            },
            {
                path: 'session',
                name: 'AdminSession',
                component: SessionPage,
            },
            {
                path: 'numeros',
                name: 'AdminNumeroForm',
                component: NumeroForm,
            },
            {
                path: 'empresas',
                name: 'AdminEmpresaForm',
                component: EmpresaForm,
            },
            {
                path: 'perguntas',
                name: 'AdminPerguntaForm',
                component: PerguntaForm,
            },
            {
                path: 'perguntas-fixas',
                name: 'AdminPerguntaFixaForm',
                component: PerguntasFixas,
            },
        ]
    },
    {
        path: '/user',
        component: UserDashboard,
        meta: { requiresAuth: true, roles: ['USER'] },
        children: [
            {
                path: 'dashboard',
                name: 'DashboardUser',
                component: DashboardUser,
            },
            {
                path: 'perguntas',
                name: 'UserPerguntaForm',
                component: PerguntaForm,
            },
            {
                path: 'session',
                name: 'UserSession',
                component: SessionPage,
            },
        ]
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const roles = to.meta.roles;
    const currentUser = localStorage.getItem('user');

    if (requiresAuth && !currentUser) {
        next({
            path: '/',
            query: { redirect: to.fullPath }
        });
    } else if (requiresAuth && currentUser) {
        const user = JSON.parse(currentUser);
        if (roles && !roles.includes(user.role)) {
            next({ path: '/' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
